*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

.navigation-desktop{

display: flex;
width: 100%;
height: 140px;
align-items: center;
justify-content: space-between;

}


*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

.navigation{
    display: flex;
    justify-content: space-between;
    height: 100px;
    align-items: center;
}

.logo{
    height: 63px;
}
